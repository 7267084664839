import React, {useState} from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {useDropzone} from 'react-dropzone'
import PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {IconWrapper} from '../../../../../components/icon-wrapper/IconWrapper'
import {UploadCloudIcon} from '../../../../../assets/icons/UploadCloudIcon'
import {FileCheckIcon} from '../../../../../assets/icons/FileCheckIcon'
import {colors} from '../../../../../theme/colors'
import {formatFileSize} from '../../../../../helpers/utils'
import {ALERT_LEVELS} from '../../../../../helpers/constants'
import {showAlert} from '../../../../../store/appGenerics'

import {
    EmptyFileDropzoneWrapper,
    FileDropzoneWrapper,
    StyledDescription,
    StyledFileDropzone,
    StyledProcessButton,
    StyledRemoveFileButton
} from './style'
import {IosSpinner} from '../../../../../components/ios-spinner/IosSpinner'
import {useTheme} from 'styled-components'

export const FileDropzone = ({
    acceptedFiles,
    onDropCb,
    maxFiles = 1,
    isDisabled,
    multiple,
    uploadProgress,
    onUpload,
    onReset,
    isProcessing,
    disableDropzone,
    uploadSizeLimit
}) => {
    const maxUploadSize = uploadSizeLimit * 1000000
    const dispatch = useDispatch()

    const [dropzoneStatus, setDropzoneStatus] = useState({
        isActive: false
    })

    const {t} = useTranslation()
    const theme = useTheme()
    const {getRootProps, getInputProps} = useDropzone({
        accept: {'text/csv': ['.csv']},
        maxFiles: maxFiles,
        maxSize: maxUploadSize, //in bytes
        multiple: multiple,
        disabled: disableDropzone,
        onDrop: acceptedFiles => {
            setDropzoneStatus({
                isActive: false
            })
            onDropCb(acceptedFiles)
        },
        onDragEnter: () => {
            setDropzoneStatus({
                isActive: true
            })
        },
        onDragLeave: () => {
            setDropzoneStatus({
                isActive: false
            })
        },
        onDropRejected: () => {
            dispatch(
                showAlert({
                    message: t('errors:unsupported_file', {type: 'CSV'}),
                    level: ALERT_LEVELS.ERROR
                })
            )
        },
        noClick: isProcessing,
        noDrag: isProcessing
    })

    return (
        <StyledFileDropzone>
            <FileDropzoneWrapper isDropzoneActive={dropzoneStatus.isActive} className="wrapper">
                {acceptedFiles.length === 0 && (
                    <EmptyFileDropzoneWrapper
                        {...getRootProps()}
                        isDisabled={disableDropzone}
                        isProcessing={isProcessing}
                    >
                        <input {...getInputProps()} />
                        {acceptedFiles.length === 0 && (
                            <>
                                <IconWrapper>
                                    <UploadCloudIcon size={20} colors={colors.darkGrey} />
                                </IconWrapper>
                                <StyledDescription>
                                    <Trans i18nKey="admin:payments:dropzone:ready_title" components={[<strong />]} />
                                    <p>
                                        {t('admin:payments:dropzone:ready_subtitle', {
                                            value: formatFileSize(maxUploadSize)
                                        })}
                                    </p>
                                </StyledDescription>
                            </>
                        )}
                    </EmptyFileDropzoneWrapper>
                )}
                {isProcessing && (
                    <>
                        <IconWrapper>
                            <IosSpinner size={20} color={colors.darkGrey} />
                        </IconWrapper>
                        <StyledDescription>
                            {t('admin:payments:dropzone:uploading_title')} <b>{acceptedFiles[0]?.name}</b>
                            <p>
                                {t('admin:payments:dropzone:uploading_subtitle', {
                                    file: formatFileSize(acceptedFiles[0]?.size),
                                    progress: uploadProgress
                                })}
                            </p>
                        </StyledDescription>
                    </>
                )}
                {!isProcessing && acceptedFiles.length > 0 && (
                    <>
                        <IconWrapper>
                            <FileCheckIcon size={20} fill={colors.darkGreen} />
                        </IconWrapper>
                        <StyledDescription>
                            <b>{acceptedFiles[0]?.name}</b>
                            <div>
                                <p>{`${formatFileSize(acceptedFiles[0]?.size)} • `}</p>
                                {!isProcessing && (
                                    <StyledRemoveFileButton onClick={onReset} variant="tertiaryPrimary">
                                        {t('admin:payments:dropzone:reset_button')}
                                    </StyledRemoveFileButton>
                                )}
                            </div>
                        </StyledDescription>
                    </>
                )}
            </FileDropzoneWrapper>
            <StyledProcessButton onClick={onUpload} disabled={isDisabled}>
                {isProcessing && <IosSpinner size={16} color={theme.palette.neutral.white} />}
                {isProcessing
                    ? t('admin:payments:dropzone:processing_button')
                    : t('admin:payments:dropzone:confirm_button')}
            </StyledProcessButton>
        </StyledFileDropzone>
    )
}

FileDropzone.propTypes = {
    maxFiles: PropTypes.number,
    onDropCb: PropTypes.func.isRequired,
    uploadProgress: PropTypes.number,
    onUpload: PropTypes.func,
    isDisabled: PropTypes.bool,
    multiple: PropTypes.bool,
    onReset: PropTypes.func,
    isProcessing: PropTypes.bool,
    acceptedFiles: PropTypes.array,
    disableDropzone: PropTypes.bool,
    uploadSizeLimit: PropTypes.number
}
